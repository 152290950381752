import * as React from "react"
import { Button, Container } from "react-bootstrap"
import "./index.scss"
import { StaticImage } from "gatsby-plugin-image"
import NavigationBar from "../components/navigation_bar"
import SEO from '../components/seo';
export default function IndexPage() {
  return (
    <div>
      <SEO title="Home"/>
      <div className="top-section-index">
        <NavigationBar current="/" />
        <div className="d-flex flex-wrap align-items-center justify-content-around">
          <div className=" col-12 col-md-9 col-lg-6">
            <div className="solarEnergySolutions p-5 ">
              <div className="heading">Solar Energy Solutions</div>
              <div className="text">
                Providing your green energy company with unmatched services:
                Permit Plans | Equipment | New Customer Management
              </div>
            </div>
            <Button
              className="ml-md-5 my-3 enquireNowButton"
              variant={"warning"}
            >
              Enquire Now
            </Button>
          </div>

          <div className="d-flex px-2">
            <div className="d-flex flex-column">
              <StaticImage
                src="../images/solarEnergySolutions/Rectangle 78.png"
                className="m-1 m-md-2"
                alt="A dinosaur"
                placeholder="blurred"
              />

              <StaticImage
                src="../images/solarEnergySolutions/Rectangle 79.png"
                className="m-1 m-md-2"
                alt="A dinosaur"
                placeholder="blurred"
              />
            </div>
            <div className="d-flex flex-column ">
              <StaticImage
                src="../images/solarEnergySolutions/Rectangle 74.png"
                className="m-1 m-md-2"
                alt="A dinosaur"
                placeholder="blurred"
              />
              <StaticImage
                src="../images/solarEnergySolutions/Rectangle 76.png"
                className="m-1 m-md-2"
                alt="A dinosaur"
                placeholder="blurred"
              />
            </div>
            <div className="d-flex flex-column ">
              <StaticImage
                src="../images/solarEnergySolutions/Rectangle 75.png"
                className="m-1 m-md-2"
                alt="A dinosaur"
                placeholder="blurred"
              />
              <StaticImage
                src="../images/solarEnergySolutions/Rectangle 77.png"
                className="m-1 m-md-2"
                alt="A dinosaur"
                placeholder="blurred"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="py-5 px-4 px-lg-0">
        <div className="d-flex justify-content-around my-4 my-md-5 flex-wrap">
          <div className="whyChooseTapSolar ">
            <div className="heading">Why choose TAPL solar?</div>
            <div className="text">
              We work together with our clients to ensure that our design
              reflects their vision for the project. It is of utmost importance
              for our team to deliver a project that is not only cost-effective,
              but also one that will translate to a feasible & smooth in-field
              installation.
            </div>
          </div>
          <div className="d-flex flex-column mt-5 mt-md-0 ">
            <div className="d-flex">
              <div>
                <StaticImage
                  placeholder="blurred"
                  width={40}
                  className="mr-3 mt-2 "
                  layout="fixed"
                  src="../images/whyChooseTaplSolar/1.png"
                  alt="specialist"
                />
              </div>

              <div className="whyChooseTapSolarListText mb-4">
                We are specialists in Solar Permitting & Drafting, Installation
                Equipment, Solar PV System Maintenance and Protection.
              </div>
            </div>

            <div className="d-flex">
              <div>
              <StaticImage
                placeholder="blurred"
                className="mr-3 mt-2"
                layout="fixed"
                src="../images/whyChooseTaplSolar/2.png"
                alt="specialist"
              />
              </div>
              
              <div className="whyChooseTapSolarListText mb-4">
                We help existing roofs and new home constructions with Solar
                Planning.
              </div>
            </div>
            <div className="d-flex">
              <div>
              <StaticImage
                placeholder="blurred"
                className="mr-3 mt-2 flex-grow-1"
                layout="fixed"
                src="../images/whyChooseTaplSolar/3.png"
                alt="specialist"
              />
              </div>
              
              <div className="whyChooseTapSolarListText mb-4">
                Exceptional customer service and craftmanship
              </div>
            </div>
          </div>
        </div>
      </div>
      <Container fluid={true} className="milestones py-5">
        <div className="text-center heading mb-4">Our Milestones</div>
        <div className="d-flex justify-content-around flex-wrap">
          <div className="item">
            <div className="heading">120+</div>
            <div className="text" style={{ maxWidth: 294 }}>
              Certificates within the company
            </div>
          </div>

          <div className="item">
            <div className="heading">31,114,283+</div>
            <div className="text" style={{ maxWidth: 377 }}>
              Monthly co2 savings by our own warehouses
            </div>
          </div>
          <div className="item">
            <div className="heading">14,200+</div>
            <div className="text" style={{ maxWidth: 230 }}>
              Permits designed
            </div>
          </div>
        </div>
      </Container>

      <Container fluid={true} className="weWorkWithTheBest py-4">
        <div className="header mt-4 mb-5">
          <div className="heading">We Work With the Best</div>
          <div className="text">Coming Together To Make The World Greener</div>
        </div>
        <div className="mb-4 d-flex flex-wrap justify-content-md-around justify-content-center">
          <div className="d-flex mb-4 flex-column align-items-center justify-content-between">
            <StaticImage
              src="../images/weWorkWithTheBest/logo1.png"
              className="m-2 mb-5"
              alt="A dinosaur"
              placeholder="blurred"
              layout="fixed"
            />
            <StaticImage
              src="../images/weWorkWithTheBest/logo4.png"
              className="m-2"
              alt="A dinosaur"
              placeholder="blurred"
              layout="fixed"
            />
          </div>
          <div className="d-flex mb-4 flex-column align-items-center justify-content-between">
            <StaticImage
              src="../images/weWorkWithTheBest/logo2.png"
              className=" mb-5"
              alt="A dinosaur"
              placeholder="blurred"
              layout="fixed"
            />
            <StaticImage
              src="../images/weWorkWithTheBest/logo5.png"
              className=" "
              alt="A dinosaur"
              placeholder="blurred"
              layout="fixed"
            />
          </div>
          <div className="d-flex mb-4 flex-column align-items-center justify-content-between">
            <StaticImage
              src="../images/weWorkWithTheBest/logo3.png"
              className="m-2 mb-5"
              alt="A dinosaur"
              placeholder="blurred"
              layout="fixed"
            />
            <StaticImage
              src="../images/weWorkWithTheBest/logo6.png"
              className="m-2 "
              alt="A dinosaur"
              placeholder="blurred"
              layout="fixed"
            />
          </div>
        </div>
      </Container>
    </div>
  )
}
